<template>
  <div class="landingPage">
    <nav>
      <div class="inner flexB">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/images/logo.svg" alt="로고"
          /></router-link>
        </div>
        <router-link class="loginBtn" to="/account/login">로그인</router-link>
      </div>
    </nav>
    <section class="legalContent">
      <div class="inner">
        <h1>Terms and Conditions of Service</h1>
        <ol>
          <li>
            <h3>General</h3>
            <p>
              TalkTalkTalk mobile application (“Service”) is operated by
              TalkTalkTalk, Inc. (“TalkTalkTalk,” “us,” or “we”) with
              headquarters at 46, Digital-ro 9-gil, Geumcheon-gu, Seoul,
              Republic of Korea. Access and use of the Service is subject to the
              following Terms of Use (“Terms”). By accessing or using any part
              of the Service, you acknowledge that you have read, understood,
              and agree to be bound by these Terms including any future
              modifications. TalkTalkTalk may amend. Updates or changes to these
              Terms of Service and the date last revised will be indicated at
              the bottom of the Terms of Service. Any revisions to these Terms
              will become effective the earlier of (i) the end of a 7-day period
              or (ii) the first time you access or use the Service after such
              changes. If you do not agree to abide by these Terms, you are not
              authorized to use, access or participate in the Service.
            </p>
            <p>
              <strong
                >PLEASE NOTE THAT THESE TERMS CONTAIN A MANDATORY ARBITRATION OF
                DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN
                INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN CIRCUMSTANCES,
                RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS.</strong
              >
            </p>
          </li>
          <li>
            <h3>Description of Service</h3>
            <p>
              The Service allows users to access and use a variety of
              educational services, including learning or practicing the Korean
              language. TalkTalkTalk may, in its sole discretion and at any
              time, update, change, suspend, make improvements to or discontinue
              any aspect of the Service temporarily or permanently.
            </p>
          </li>
          <li>
            <h3>Registration</h3>
            <p>
              In connection with registering for and using the Service, you
              agree (i) to provide accurate, current and complete information
              about you and/or your organization as requested by TalkTalkTalk;
              (ii) to maintain the confidentiality of your password and other
              information related to the security of your account, (iii) to
              maintain and promptly update any registration information you
              provide to TalkTalkTalk, and to keep such information accurate,
              current and complete, and (iv) to be fully responsible for all use
              of your account and for any actions that take place through your
              account.
            </p>
          </li>
          <li>
            <h3>In-App Purchases</h3>
            <p>
              If you purchase an auto-renewing periodic subscription through
              TalkTalkTalk, your TalkTalkTalk account will be billed
              continuously for the subscription until you terminate it as set
              forth below. After your initial subscription period, and again
              after any subsequent subscription period, your subscription will
              automatically renew for an additional equivalent period. If you do
              not wish your subscription to renew automatically, or if you want
              to change or terminate your subscription, you will need to log in
              to your TalkTalkTalk account and follow instructions to terminate
              or change your subscription, even if you have deleted your
              account.
            </p>
            <p>
              TalkTalkTalk may manage, regulate, control, modify, or eliminate
              Virtual Items at any time, with or without notice. TalkTalkTalk
              may update the pricing of Virtual Items at any time in its sole
              discretion, and may add new Virtual Items for additional fees.
              TalkTalkTalk shall have no liability to you or any third party in
              the event that TalkTalkTalk exercises any such rights.
            </p>
            <p>
              You agree to pay all fees and applicable taxes incurred by you or
              anyone using a TalkTalkTalk account registered to you.
              TalkTalkTalk may revise the pricing for the goods and services
              offered through the Service at any time. All information that you
              provide in connection with a purchase or transaction or other
              monetary transaction interaction with the Service must be
              accurate, complete, and current. You agree to pay all charges
              incurred by users of your credit card, debit card, or other
              payment method used in connection with a purchase or transaction
              or other monetary transaction interaction with the Service at the
              prices in effect when such charges are incurred. You will pay any
              applicable taxes, if any, relating to any such purchases,
              transactions or other monetary transaction interactions.
            </p>
          </li>
          <li>
            <h3>Your Representations and Warranties</h3>
            <p>
              You represent and warrant to TalkTalkTalk that your access and use
              of the Service will be in accordance with these Terms and with all
              applicable laws, rules and regulations of the Republic of Korea
              and any other relevant jurisdiction, including those regarding
              online conduct or acceptable content, and those regarding the
              transmission of data or information exported from the Republic of
              Korea and/or the jurisdiction in which you reside. You further
              represent and warrant that you have created or own any material
              you submit via the Service and that you have the right, as
              applicable, to grant us a license to use that material as set
              forth above or the right to assign that material to us as set
              forth below.
            </p>
          </li>
          <li>
            <h3>Indemnification of TalkTalkTalk</h3>
            <p>
              Subject to the terms of these Terms, TalkTalkTalk grants you a
              non-transferable, non-exclusive license to download, install, and
              use a copy of the App in object code form only on an interactive
              wireless device that you own or control. You may not derive or
              attempt to derive the source code of all or any portion of any
              App, permit any third party to derive or attempt to derive such
              source code, or reverse engineer, decompile, disassemble, or
              translate any App or any part thereof. TalkTalkTalk and its
              licensors own and shall retain all intellectual property rights
              and other rights in and to the App, and any changes,
              modifications, or corrections thereto. The following terms apply
              to you only if you are using the App from the Apple GooglePlay
              Store. he extent the other terms and conditions of these Terms are
              less restrictive than, or otherwise conflict with, the terms and
              conditions of this paragraph, the more restrictive or conflicting
              terms and conditions in this paragraph apply, but solely with
              respect to App from the Apple GooglePlay store. You acknowledge
              and agree that these Terms are solely between you and
              TalkTalkTalk, not Apple, and that Apple has no responsibility for
              the Apps or content thereof. Your use of any App must comply with
              the GooglePlay store Terms of Service. You acknowledge that Apple
              has no obligation whatsoever to furnish any maintenance and
              support services with respect to the Apps. You and TalkTalkTalk
              acknowledge that Apple is not responsible for addressing any
              claims of you or any third party relating to the Apps or your
              possession and/or use of any App, including, but not limited to:
              (i) product liability claims; (ii) any claim that an App fails to
              conform to any applicable legal or regulatory requirement; and
              (iii) claims arising under consumer protection or similar
              legislation. You and TalkTalkTalk acknowledge that, in the event
              of any third-party claim that any App or your possession and use
              of that App infringes that third party’s intellectual property
              rights, TalkTalkTalk, not Apple, will be solely responsible for
              the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim to the extent required by
              these Terms. You must comply with applicable third party terms of
              agreement when using any App. You and TalkTalkTalk acknowledge and
              agree that Apple, and Apple’s subsidiaries, are third party
              beneficiaries of these Terms as they relate to your license of the
              Apps, and that, upon your acceptance of these Terms, Apple will
              have the right (and will be deemed to have accepted the right) to
              enforce these Terms and Conditions against you as a third party
              beneficiary thereof.
            </p>
          </li>
          <li>
            <h3>License to Apps</h3>
            <p>
              Subject to the terms of these Terms, TalkTalkTalk grants you a
              non-transferable, non-exclusive license to download, install, and
              use a copy of the App in object code form only on an interactive
              wireless device that you own or control. You may not derive or
              attempt to derive the source code of all or any portion of any
              App, permit any third party to derive or attempt to derive such
              source code, or reverse engineer, decompile, disassemble, or
              translate any App or any part thereof. TalkTalkTalk and its
              licensors own and shall retain all intellectual property rights
              and other rights in and to the App, and any changes,
              modifications, or corrections thereto. The following terms apply
              to you only if you are using the App from the Apple GooglePlay
              store. he extent the other terms and conditions of these Terms are
              less restrictive than, or otherwise conflict with, the terms and
              conditions of this paragraph, the more restrictive or conflicting
              terms and conditions in this paragraph apply, but solely with
              respect to App from the Apple GooglePlay store. You acknowledge
              and agree that these Terms are solely between you and
              TalkTalkTalk, not Apple, and that Apple has no responsibility for
              the Apps or content thereof. Your use of any App must comply with
              the App Store Terms of Service. You acknowledge that Apple has no
              obligation whatsoever to furnish any maintenance and support
              services with respect to the Apps. You and TalkTalkTalk
              acknowledge that Apple is not responsible for addressing any
              claims of you or any third party relating to the Apps or your
              possession and/or use of any App, including, but not limited to:
              (i) product liability claims; (ii) any claim that an App fails to
              conform to any applicable legal or regulatory requirement; and
              (iii) claims arising under consumer protection or similar
              legislation. You and TalkTalkTalk acknowledge that, in the event
              of any third-party claim that any App or your possession and use
              of that App infringes that third party’s intellectual property
              rights, TalkTalkTalk, not Apple, will be solely responsible for
              the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim to the extent required by
              these Terms. You must comply with applicable third party terms of
              agreement when using any App. You and TalkTalkTalk acknowledge and
              agree that Apple, and Apple’s subsidiaries, are third party
              beneficiaries of these Terms as they relate to your license of the
              Apps, and that, upon your acceptance of these Terms, Apple will
              have the right (and will be deemed to have accepted the right) to
              enforce these Terms and Conditions against you as a third party
              beneficiary thereof.
            </p>
          </li>
          <li>
            <h3>NO REPRESENTATIONS OR WARRANTIES BY TalkTalkTalk</h3>
            <p>
              THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT
              THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR
              PROVIDED TO YOU BY TalkTalkTalk ARE PROVIDED TO YOU ON AN “AS IS”
              BASIS. TalkTalkTalk AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR
              WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS
              OR IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
              NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE
              GENERALITY OF THE FOREGOING, TalkTalkTalk DOES NOT MAKE ANY
              REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY,
              SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT,
              ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM USE, OR
              NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY BE UNAVAILABLE
              DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR
              SCHEDULED OR UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO
              THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            </p>
          </li>
          <li>
            <h3>LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h3>
            <p>
              IN NO EVENT SHALL TalkTalkTalk BE LIABLE TO YOU OR ANY THIRD PARTY
              CLAIMING THROUGH YOU (WHETHER BASED IN CONTRACT, TORT, STRICT
              LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO
              THE ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR USE, THE
              SERVICE OR ANY PORTION THEREOF, INCLUDING BUT NOT LIMITED TO THE
              LOSS OF USE OF THE SERVICE, INACCURATE RESULTS, LOSS OF PROFITS,
              BUSINESS INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR CORRUPTION
              OF DATA OR DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING
              ANY DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD
              PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, TELEPHONES
              OR OTHER PROPERTY, EVEN IF TalkTalkTalk HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. TalkTalkTalk’S LIABILITY TO YOU OR
              ANY THIRD PARTY CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND
              REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT
              PAID, IF ANY, BY YOU TO TalkTalkTalk FOR THE SERVICE IN THE 12
              MONTHS PRIOR TO THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS
              IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM
              HEREUNDER WILL NOT INCREASE THIS LIMIT.
            </p>
          </li>
          <li>
            <h3>TERMINATION</h3>
            <p>
              TalkTalkTalk may terminate your access and use of the Service
              immediately at any time, for any reason, and at such time, you
              will have no further right to use the Service. You may terminate
              your TalkTalkTalk account at any time by following the
              instructions available through the Service. The provisions of
              these Terms relating to the protection and enforcement of
              TalkTalkTalk’s proprietary rights, your representations and
              warranties, disclaimer of representations and warranties, release
              and indemnities, limitations of liability and types of damages,
              ownership of data and information, governing law and venue, and
              miscellaneous provisions shall survive any such termination.
            </p>
          </li>
          <li>
            <h3>
              PROPRIETARY RIGHTS IN SERVICE CONTENT AND ACTIVITY MATERIALS
            </h3>
            <p>
              All content available through the Service, including designs,
              text, graphics, images, information, software, audio and other
              files, and their selection and arrangement (the "Service
              Content"), are the proprietary property of TalkTalkTalk. No
              Service Content may be modified, copied, distributed, framed,
              reproduced, republished, downloaded, scraped, displayed, posted,
              transmitted, or sold in any form or by any means, in whole or in
              part, other than as expressly permitted in these Terms. You may
              not use any data mining, robots, scraping or similar data
              gathering or extraction methods to obtain Service Content. As
              between you and TalkTalkTalk, all data, information and materials
              generated from your access and use of the educational activities
              made available on or through the Service, including translated
              content generated by you (collectively, the “Activity Materials”),
              shall be exclusively owned by TalkTalkTalk, and you shall not have
              any right to use such Activity Materials except as expressly
              authorized by these Terms. Activity Materials will not include
              Translation Materials. By using the Service, you hereby assign to
              TalkTalkTalk any and all rights, title and interest, including any
              intellectual property rights or proprietary rights, in the
              Activity Materials. All rights of TalkTalkTalk that are not
              expressly granted in these Terms are reserved to TalkTalkTalk.
            </p>
          </li>
          <li>
            <h3>COPYRIGHTS, TRADEMARK RIGHTS, AND PROPERTY RIGHTS</h3>
            <p>
              ALL contents provided in the Service are protected by copyright.
              Also, “TalkTalkTalk” and all other trademarks, service marks,
              graphics logos used in connection with the Service are trademarks
              or service marks of TalkTalkTalk or their respective owners, and
              are registered with the Korean Intellectual Property Office.
              Access and use of the Service does not grant or provide you with
              the right or license to reproduce or otherwise use the
              TalkTalkTalk name or any TalkTalkTalk or third-party trademarks,
              service marks, graphics or logos.
            </p>
          </li>
          <li>
            <h3>LANGUAGE</h3>
            <p>
              This agreement was originally written in English (US). If any
              translated version of this agreement conflicts with the English
              version, the English version controls.
            </p>
          </li>
          <li>
            <h3>MISCELLANEOUS</h3>
            <p>
              These Terms constitute the entire agreement between TalkTalkTalk
              and you concerning the subject matter hereof. In the event that
              any of the Terms are held by a court or other tribunal of
              competent jurisdiction to be unenforceable, such provisions shall
              be limited or eliminated to the minimum extent necessary so that
              these Terms shall otherwise remain in full force and effect. A
              waiver by TalkTalkTalk or you of any provision of these Terms or
              any breach thereof, in any one instance, will not waive such term
              or condition or any subsequent breach thereof. TalkTalkTalk may
              assign its rights or obligations under these Terms without
              condition. These Terms will be binding upon and will inure to the
              benefit of TalkTalkTalk and you, and TalkTalkTalk’s and your
              respective successors and permitted assigns.
            </p>
          </li>
          <li>
            <h3>Contact Us</h3>
            <p class="mb0">
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>
            <ul>
              <li>
                By email:
                <a href="mailto:batoncompany.caster@gmail.com"
                  >batoncompany.caster@gmail.com</a
                >
              </li>
            </ul>
            <p>Effective date: 9 June 2024</p>
          </li>
        </ol>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
